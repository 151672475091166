/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useMemo } from 'react'

import BackgroundImage from '@assets/images/background_abs.svg'
import { keyframes } from '@emotion/react'
import { Flexbox } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

import Sphere from './Sphere'

const OrbitBackground = (
  props: FlexboxTypes.Props & {
    orbits?: boolean
    scale?: number
  },
) => {
  const { orbits, scale = 1, ...restProps } = props

  const { animationA, animationB } = useMemo(() => {
    const A = keyframes`
    0% {
      transform: rotate(0deg) scale(${scale}); 
    } 45% {
      transform: rotate(180deg) scale(${scale}); 
    } 100% {
      transform: rotate(360deg) scale(${scale}); 
    }`
    const B = keyframes`
    0% {
      transform: rotate(0deg) scale(${scale}); 
    } 45% {
      transform: rotate(-180deg) scale(${scale}); 
    } 100% {
      transform: rotate(-360deg) scale(${scale}); 
    }`
    return { animationA: A, animationB: B }
  }, [scale])

  return (
    <Flexbox
      {...restProps}
      centered
      style={{
        background: `url(${BackgroundImage}) no-repeat`,
        backgroundSize: 'cover',
        position: 'absolute',
        width: '112.5rem',
        height: '112.5rem',
        zIndex: -1,
        animation: `${animationA} linear 400s infinite`,
        // @ts-ignore
        ...props.style,
      }}
    >
      <Flexbox
        centered
        style={{
          background: `url(${BackgroundImage}) no-repeat`,
          backgroundSize: 'cover',
          width: '100rem',
          height: '100rem',
          animation: `${animationB} linear 250s infinite`,
        }}
      >
        <Flexbox
          centered
          style={{
            background: `url(${BackgroundImage}) no-repeat`,
            backgroundSize: 'cover',
            width: '75rem',
            height: '75rem',
            animation: `${animationA} linear 250s infinite`,
          }}
        >
          <Flexbox
            centered
            style={{
              background: `url(${BackgroundImage}) no-repeat`,
              backgroundSize: 'cover',
              width: '50rem',
              height: '50rem',
              animation: `${animationB} linear 150s infinite`,
              border: orbits ? '1px solid rgba(0,0,0,0.1)' : undefined,
              borderRadius: '100%',
            }}
          >
            <Flexbox
              centered
              style={{
                background: `url(${BackgroundImage}) no-repeat`,
                backgroundSize: 'cover',
                width: '25rem',
                height: '25rem',
                animation: `${animationB} linear 180s infinite`,
              }}
            >
              {orbits && (
                <Flexbox
                  centered
                  style={{
                    background:
                      'linear-gradient(-90deg, rgba(5, 150, 105, 0.3) 0%, rgba(5, 150, 105, 0.1))',
                    width: '100%',
                    height: '100%',
                    borderRadius: '100%',
                    animation: `${animationB} linear 150s infinite`,
                  }}
                >
                  <Flexbox
                    centered
                    style={{
                      background:
                        'linear-gradient(-90deg, rgba(215, 255, 240, 1) 0%, rgba(255, 255, 255, 1))',

                      width: 'calc(100% - 0.125rem)',
                      height: 'calc(100% - 0.125rem)',
                      borderRadius: '100%',
                    }}
                  >
                    <Sphere
                      scale={0.2}
                      hue={0}
                      style={{
                        position: 'absolute',
                        left: '1rem',
                        top: 0,
                      }}
                    />
                    <Sphere
                      scale={0.1}
                      hue={150}
                      style={{
                        position: 'absolute',
                        left: '-2rem',
                        top: '4rem',
                      }}
                    />
                    <Sphere
                      scale={0.1}
                      hue={200}
                      style={{
                        position: 'absolute',
                        left: '15rem',
                        top: '-1.75rem',
                      }}
                    />
                    <Sphere
                      scale={0.1}
                      hue={300}
                      style={{
                        position: 'absolute',
                        left: '20rem',
                        top: '15.75rem',
                      }}
                    />
                  </Flexbox>
                </Flexbox>
              )}
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}

export default OrbitBackground
